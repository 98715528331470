import React from "react";
import SectionTitle from "..//sectionTitle/SectionTitle";
import CategoryFour from "../category/CategoryFour";

const HomeFiveCategories = () => {
  return (
    <div className="eduvibe-home-five-cats edu-about-area about-style-5 edu-section-gapTop bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5">
          <div className="col-lg-12">
            <SectionTitle
              slogan="Top Categories"
              title=""
            />
          </div>
        </div>
        <div className="row">
          <CategoryFour />
        </div>
      </div>
    </div>
  );
};

export default HomeFiveCategories;
