import Axios from "axios";
import { toast } from "react-toastify";
import {
  EXPERT_UPDATE_PROFILE_FAIL,
  EXPERT_UPDATE_PROFILE_REQUEST,
  EXPERT_UPDATE_PROFILE_SUCCESS,
  EXPERT_REVIEW_CREATE_REQUEST,
  EXPERT_REVIEW_CREATE_SUCCESS,
  EXPERT_REVIEW_CREATE_FAIL,
} from "../constants/expertConstants ";
import { USER_SIGNIN_SUCCESS } from "../constants/userConstants";

export const updateExpertProfile = (expert) => async (dispatch, getState) => {
  dispatch({ type: EXPERT_UPDATE_PROFILE_REQUEST, payload: expert });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(
      ` ${process.env.REACT_APP_API_KEY}expert/profile`,
      expert,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    dispatch({ type: EXPERT_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    toast.success("✅ profile updated successfully! 😊");
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: EXPERT_UPDATE_PROFILE_FAIL, payload: message });
    toast.error("❌ profile update failed 😔", message);
  }
};

export const createReview =
  (expertId, review) => async (dispatch, getState) => {
    dispatch({ type: EXPERT_REVIEW_CREATE_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        ` ${process.env.REACT_APP_API_KEY}expert/${expertId}/reviews`,
        review,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: EXPERT_REVIEW_CREATE_SUCCESS,
        payload: data.review,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: EXPERT_REVIEW_CREATE_FAIL, payload: message });
    }
  };
