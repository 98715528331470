import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import "./Banner.css";

import videoa from "../../assets/images/videob.mp4";

function Banner() {
  return (
    <div className="banner-wrap container">
      <div className="main-banner">
        <div className="contentb">
          <h3 id="h1-banner">
            Get expert guidance and personalized care for &nbsp;
            <br />
            <span id="span-react-node">
              <Typewriter
                words={[
                  "Polycystic Ovary Syndrome",
                  "Metabolic Syndrome",
                  "Hormonal Imbalance",
                  "Obesity ",
                  "Mental Health",
                ]}
                loop={0}
                typeSpeed={120}
                deleteSpeed={80}
                delaySpeed={1000}
              />
            </span>
          </h3>
          <p id="p-tag">
            Balm.ai provides a comprehensive approach to weight management,
            focusing on both physical and mental well-being.
          </p>

          <Link
            to="/user/signin"
            className="edu-btn btn-medium left-icon header-button"
          >
            GET STARTED
          </Link>
        </div>

        {/* <img className="img" src={img} alt="banner"></img> */}
      </div>
      <div className="banner-video-wrap">
        <video
          autoPlay
          loop
          muted
          src={videoa}
          playsInline
          className="img"
        ></video>
      </div>
    </div>
  );
}

export default Banner;
