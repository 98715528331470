import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";

const items = [
  {
    title: "PCOS Care",
    numberOfCourses: "45 Courses",
    image:
      "https://ouch-cdn2.icons8.com/yljl5PuEKbTMPQ4acIUaY4hVNNEjwT3kcrrNgVRi6ws/rs:fit:256:256/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNTM0/L2QwYzgwMTZkLWI4/OWQtNDdkZi05OTU0/LWM5ODNkYjQ2MjZk/NS5zdmc.png",
    shape: "shape-bg-1",
    link: "#",
  },
  {
    title: "Mental Health",
    numberOfCourses: "23 Courses",
    image:
      "https://ouch-cdn2.icons8.com/TIZH6OALMDBkAwykhBeW9dVfdrvWB6UW9NqDw8iEays/rs:fit:256:192/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvODcx/LzdiOGMzYmVjLTkw/NTEtNGY0MC04MmJj/LWJlNzc2NjNjNzdk/NC5zdmc.png",
    shape: "shape-bg-2",
    link: "#",
  },
  {
    title: "Metabolic Health",
    numberOfCourses: "30 Courses",
    image:
      "https://ouch-cdn2.icons8.com/yljl5PuEKbTMPQ4acIUaY4hVNNEjwT3kcrrNgVRi6ws/rs:fit:256:256/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNTM0/L2QwYzgwMTZkLWI4/OWQtNDdkZi05OTU0/LWM5ODNkYjQ2MjZk/NS5zdmc.png",
    shape: "shape-bg-5",
    link: "#",
  },
  {
    title: "Wellness & Lifestyle",
    numberOfCourses: "25 Courses",
    image:
      "https://ouch-cdn2.icons8.com/yljl5PuEKbTMPQ4acIUaY4hVNNEjwT3kcrrNgVRi6ws/rs:fit:256:256/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNTM0/L2QwYzgwMTZkLWI4/OWQtNDdkZi05OTU0/LWM5ODNkYjQ2MjZk/NS5zdmc.png",
    shape: "shape-bg-4",
    link: "#",
  },
];

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slide-arrow prev-arrow" onClick={onClick}>
      <i className="icon-arrow-left-line"></i>
    </button>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slide-arrow next-arrow" onClick={onClick}>
      <i className="icon-arrow-right-line"></i>
    </button>
  );
};

const CategoryFour = ({ wrapperClass, itemClass }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider
      className={
        wrapperClass ||
        "col-lg-12 mt--60 mb_dec--20 slick-activation-wrapper service-activation-item5 edu-slick-arrow-top"
      }
      {...sliderSettings}
    >
      {items.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          className={itemClass || "single-slick-card"}
          animateOnce={true}
          key={i}
        >
          <div className={`service-card service-card-8 ${data.shape}`}>
            <div className="inner">
              <div className="icon">
                <Link to="/experts/market-place">
                  <img src={`${data.image}`} alt="Category Thumb" />
                </Link>
              </div>
              <div className="content">
                <h6 className="title">
                  <Link to="/experts/market-place">{data.title}</Link>
                </h6>
                {/* <span>{ data.numberOfCourses }</span> */}
              </div>
              <div className="hover-action">
                <Link className="read-more-btn" to="/experts/market-place">
                  <i className="icon-arrow-right-line-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </Slider>
  );
};

export default CategoryFour;
