export const data = {
  title: "Care Focus Areas",
  steps: [
    {
      title: "PCOS (Polycystic Ovary Syndrome) ",
      description:
        "We offer expert guidance to help manage PCOS symptoms, address hormone imbalances, and support reproductive health",
    },
    {
      title: "Metabolic Syndrome",
      description:
        " Our personalized nutrition and fitness plans assist women in tackling metabolic health challenges.",
    },
    {
      title: "Hormonal Imbalances",
      description:
        "We provide resources to help manage hormonal fluctuations and alleviate related symptoms.",
    },
    {
      title: "Obesity",
      description:
        "Our personalized nutrition and fitness plans and support are designed to help you achieve and maintain a healthy weight.",
    },
    {
      title: "Mental Wellness",
      description: "We focus on strategies to reduce stress, and enhance mood.",
    },
  ],
};

export const data2 = {
  title: "Take Control of Your Health.",
  steps: [
    {
      title: "Expert Guidance",
      description:
        "Access to nutritionists, dieticians, fitness experts, gynecologists, and therapists.",
    },
    {
      title: "Personalized Care",
      description:
        "Tailored plans to meet your unique needs and goals, with specialized support for PCOS, metabolic syndrome, hormonal imbalances, endometriosis, and weight management.",
    },
    {
      title: "Convenient & Accessible",
      description:
        "Virtual consultations, online resources, and support are available anytime, anywhere.",
    },
  ],
};

export const data3 = {
  title: "Our Services ",
  steps: [
    {
      title: "Nutrition",
      description:
        "Customized meal plans and expert guidance to promote healthy eating habits.",
    },
    {
      title: "Fitness",
      description:
        "Tailored workout programs designed to align with your lifestyle and personal preferences.",
    },
    {
      title: "Mental Health",
      description:
        "Therapy and counseling services aimed at supporting your emotional well-being",
    },
  ],
};

export const data4 = {
  title: "Personalized Care for Your Unique Needs",
  steps: [
    {
      title: "Sign up",
      description: "Create your free account.",
    },
    {
      title: "Complete Your Profile",
      description: "Tell us about your health history, goals, and lifestyle.",
    },
    {
      title: "Take the Assessment",
      description:
        "Answer key questions to help us understand your culture and unique needs.",
    },
    {
      title: "Explore Personalized Plans",
      description: "Discover tailored options designed just for you.",
    },
    {
      title: "Choose Your Plan",
      description:
        "Subscribe to the plan that best fits your goals and budget.",
    },
    {
      title: "Achieve Your Goals",
      description:
        "Begin your journey toward optimal health with expert support and continuity of care.",
    },
  ],
};
